import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import Layout from '../components/Layout';
import LandingPage from '../pages/LandingPage';

const router = createBrowserRouter([
	{
		path: '/',
		element: <Layout />,
		children: [
			{
				path: '',
				element: <LandingPage />
			}
		]
	}
]);

export default router;
