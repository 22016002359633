import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import config from '../../config.json';

import Footer from './Footer';
import Header from './Header';
import Theme from '../../theme';
import slyceBackground from '../../assets/background/Slyce360_021822_Member-Landing-Page_Graphic.jpg';

import Box from '@mui/material/Box';

const Layout = ({ children }) => {
	return (
		<Theme>
			<Box
				sx={{
					display: 'flex',
					minHeight: '100vh'
				}}
			>
				<Box
					sx={{
						backgroundRepeat: 'no-repeat',
						WebkitBackgroundSize: 'cover',
						MozBackgroundSize: 'cover',
						OBackgroundSize: 'cover',
						backgroundSize: 'cover',
						backgroundImage: `url(${slyceBackground})`
					}}
				>
					<Box
						sx={{
							display: 'flex',
							width: '100vw'
						}}
					>
						<Header />
						<Outlet />
						<Footer />
					</Box>
				</Box>
			</Box>
		</Theme>
	);
};

Layout.propTypes = {
	authUserSession: PropTypes.func,
	children: PropTypes.element,
	location: PropTypes.object,
	isAuthed: PropTypes.bool,
	t: PropTypes.func
};

export default Layout;
