import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { emailValidation } from '../../../validation/emailValidation';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const EmailInput = ({
	t,
	emailInput,
	setEmailInput,
	emailError,
	setEmailError
}) => {
	const { error: userPoolsError } = useSelector(
		(state) => state.userPoolsSlice.userPools
	);

	const handleInputValidation = (value) => {
		const emailValidationError = emailValidation(value);

		if (emailValidationError.length) {
			setEmailError(emailValidationError);
		} else {
			setEmailError([]);
		}
		setEmailInput(value);
	};

	return (
		<React.Fragment>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center'
				}}
			>
				<Typography
					sx={{
						fontSize: '1.4rem',
						fontWeight: 500,
						lineHeight: 1.6
					}}
				>
					{t('pages.landingpage.greeting')}
				</Typography>
			</Box>
			<Box justifyContent='center' alignContent='center' sx={{ flex: 1 }}>
				<TextField
					sx={{ width: '15vw' }}
					error={emailInput !== '' && emailError.length !== 0}
					helperText={
						emailInput !== '' &&
						emailError.length !== 0 &&
						t('pages.landingpage.error')
					}
					label='Enter Email'
					onChange={(e) => handleInputValidation(e.target.value)}
					variant='outlined'
					value={emailInput}
				/>
			</Box>
		</React.Fragment>
	);
};

EmailInput.propTypes = {
	t: PropTypes.func
};

export default withTranslation()(EmailInput);
