import React from 'react';
import Navbar from './Header/Navbar';

import Box from '@mui/material/Box';

const Header = (props) => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				margin: '0px',
				position: 'fixed',
				width: '100vw',
				zIndex: 9999,
				height: (theme) => theme.panel.height
			}}
		>
			<Navbar />
		</Box>
	);
};

export default Header;
