export default {
	components: {
		layout: {
			footer: {
				copyright: 'Slyce360',
				rights: 'All Rights Reserved.'
			}
		},
		router: {}
	},
	pages: {
		landingpage: {
			back: 'Back',
			greeting: 'Welcome to Slyce360',
			error: 'Invalid Email Address',
			emailNotFoundLine1: 'Unable to locate any related organizations',
			emailNotFoundLine2:
				'Please try a different email or reach out to your admin.',
			finish: 'Finish',
			next: 'Next',
			organization: 'Select An Organization',
			stepper: {
				email: 'Email Input',
				organization: 'Select An Organization'
			}
		}
	}
};
